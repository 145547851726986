<template>
	<div class="dashboard">
    <div class="dashboard__container">
      <div class="dashboard__container--header">
        <h1>Add Group</h1>
        <button class="btn btn__flat" @click="goBack"><i class="fas fa-arrow-left fa-2x"></i></button>
      </div>
      <form ref="form" @submit.prevent>
        <div class="dashboard__container--body">
          <div class="dashboard__container--body--col">
            <div class="mb-3">
              <label for="title">Group Title:</label>
              <input class="mb-5" type="text" v-model.trim="group.title" id="title" />
              <div class="mb-3">
                <label for="pay">Pay Starting At:</label>
                <input type="text" v-model.trim="group.startingPay" id="pay" />
              </div>
              <div class="mb-3">
                <label for="groupVisible">Visible on Website:</label>
                <input type="checkbox" v-model.trim="group.visible" id="groupVisible" class="ml-3" />
              </div>

              <div class="mb-3">
                <label for="grouprequiresEmployees">Requires Employees:</label>
              <input type="checkbox" v-model.trim="group.requiresEmployees" id="grouprequiresEmployees" class="mb-5 ml-3" />
              </div>

              <div class="mb-3">
                <label for="groupAmazon">Ask if they've worked at Amazon:</label>
                <input type="checkbox" v-model.trim="group.amazon" id="groupAmazon" class="mb-5 ml-3" />
              </div>
<!-- 
              <div>
                <label for="location">Location (Ex: Monterey, CA):</label>
                <input type="text" v-model.trim="group.location" id="location" class="mb-5"  />
              </div> -->
              <div class="mb-3">
                <label for="location">Location: Ex: Monterey, CA):</label>
                <label>
                  <gmap-autocomplete
                    @place_changed="setPlace" :options="{fields: ['geometry', 'address_components']}" placeholder="City and State">
                  </gmap-autocomplete>
                </label>
              </div>

              <div class="mb-3">
                <label for="pickDate">Specify Work Type:</label>
                <v-select
                  class="mt-2"
                  label="title" 
                  :options="types"
                  v-model="group.type"
                  multiple
                  >
                </v-select>
              </div>


              <label for="pickDate">Specify Jobs:</label>
              <v-select
                class="mt-2 mb-5"
                label="title" 
                :options="jobs"
                v-model="group.job"
                multiple
                taggable
                  push-tags
                >
              </v-select>

              <label for="desc">Group Description:</label>
              <vue-editor id="desc" v-model="group.description" required></vue-editor>
              <!-- <textarea name="desc" id="desc" cols="30" rows="10" v-model="group.description"></textarea> -->
            </div>
          </div>
          <div class="dashboard__container--body--col">
            <div class="mb-3" v-if="(reps.length >= 1)">
              <label for="rep">Account Admins:</label>
              <v-select
                class="mt-2 mb-5"
                label="name" 
                :options="reps"
                v-model="group.admins"
                multiple
                >
              </v-select>

              <label for="rep">Account Users:</label>
              <v-select
              v-if="(reps.length >= 1)"
                class="mt-2"
                label="name" 
                :options="reps"
                v-model="group.users"
                multiple
                >
              </v-select>
            </div>
            <div class="mb-3">
              <button class="btn btn__primary mt-2" @click="addGroup()">Create Group
                <transition name="fade">
                  <span class="ml-2" v-if="performingRequest">
                  <i class="fa fa-spinner fa-spin"></i>
                  </span>
                </transition>
              </button>
              <!-- <button class="btn btn__primary mt-2" @click="addUsers()">Update Users
                <transition name="fade">
                  <span class="ml-2" v-if="performingRequest2">
                  <i class="fa fa-spinner fa-spin"></i>
                  </span>
                </transition>
              </button> -->
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import router from '@/router'
import { VueEditor } from "vue2-editor";

export default {
  name: 'addgroup',
  data: () => ({
    group: {
      title: '',
      description: '',
      visible: true
    },
    performingRequest: false,
    marker: {},
    address: {},
    place: '',
    currentPlace: null,
    types: ['Full Time', 'Part Time', 'On-Call']
  }),
  computed: {
    ...mapState(['currentUser','jobs', 'mgrs', 'clients', 'venues']),
    reps: function() {
      return this.mgrs.filter(mgr => {
        return mgr.userId
      })
    },
  },
  async mounted () {
    if (!this.mgrs || this.mgrs.length < 1) {
      this.$store.dispatch("getMgrsState")
    }
    if (!this.jobs || this.jobs.length < 1) {
      this.$store.dispatch("getJobsState")
    }
    if (!this.venues || this.venues.length < 1) {
      this.$store.dispatch("getVenues")
    }
  },
  methods: {
    goBack() {
      router.go(-1)
    },
    setPlace(place) {
      this.currentPlace = place
        if (this.currentPlace) {
          const marker = {
            lat: this.currentPlace.geometry.location.lat(),
            lng: this.currentPlace.geometry.location.lng()
          };
          this.marker = {position: marker}
          this.place = this.currentPlace
          this.center = marker;
          this.currentPlace = null;
        }
        if (place.address_components) {
          var components = place.address_components;
          for (var i = 0, component; component = components[i]; i++) {
          if (component.types[0] == 'street_number') {
              this.address.street_number = component['long_name'];
          }
          if (component.types[0] == 'route') {
              this.address.street = component['long_name'];
          }
          if (component.types[0] == 'locality') {
              this.address.city = component['long_name'];
          }
          if (component.types[0] == 'administrative_area_level_1') {
              this.address.state = component['short_name'];
          }
          if (component.types[0] == 'country') {
              this.address.country = component['short_name'];
          }
          if (component.types[0] == 'postal_code') {
              this.address.zip = component['short_name'];
          }
        }
      }
    },
    addGroup() {
      this.performingRequest = true
      let group = this.group
      let store = this.$store
      if (this.center) {
        group.center = this.center
      }
      if (this.address.city && this.address.state) {
        group.location = this.address.city + ', ' + this.address.state
      }
      group.owner = this.currentUser.uid
      store.dispatch('addGroup', group)

      setTimeout(() => {
        this.performingRequest = false
        group = {}
        let url = `/groups`
        router.push(url)
      }, 1000)
    },
  },
  components: {
    VueEditor,
  },
  beforeDestroy () {
    this.$store.dispatch('clearJobsState')
    this.$store.dispatch('clearVenuesState')
    this.group = null
    delete this.group
    this.performingRequest = null
    delete this.performingRequest
    this.marker = null
    delete this.marker
    this.address = null
    delete this.address
    this.place = null
    delete this.place
    this.currentPlace = null
    delete this.currentPlace
    this.types = null
    delete this.types
    delete this.setPlace
    delete this.addGroup
  }
}
</script>